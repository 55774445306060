import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const buttonDefaultClassNames = /* tw */ {
    button: 'btn inline-flex gap-4 items-center justify-center disabled:cursor-not-allowed relative transition-common flex-grow whitespace-nowrap disabled:opacity-30 group/button',
    button__full: 'w-full',
    button__printHidden: 'print:hidden',
    button__custom: '',
    button__loading: 'disabled:!opacity-100',
    iconContainer: 'btn__ico-container',
    icon: 'ico ico--btn',
    icon__active: '',
    icon__disabled: '',
    icon__custom: '',
    loader: 'loader loader--btn',
    content: 'btn__content',
    content__custom: '',
    content__mobile: 'hidden sm:inline',
    contentMobile: 'sm:hidden',
    badge: 'btn__badge',
    badge__gap: '-ml-2',
    textBadge: 'btn__text-badge font-normal',
    badgeIconContainer: '',
    badgeIcon: '',
    // primární tlačítko
    primary: {
        button: 'btn--primary bg-gray-70 border border-gray-70 text-white hover:bg-black hover:border-black focus-within:border-black focus-within:bg-black radius-form content-center disabled:!opacity-100 disabled:bg-gray-10 disabled:border-gray-10 disabled:hover:bg-gray-10 disabled:hover:border-gray-10 disabled:focus-within:bg-gray-10 disabled:focus-within:border-gray-10 disabled:text-gray-30',
        textBadge: 'text-gray-30',
        icon: 'w-6 h-6 fill-white',
        loader: 'w-6 h-6 text-white',
    },
    // sekundární tlačítko
    secondary: {
        button: 'btn--secondary bg-gray-10 text-gray-70 border border-gray-10 hover:bg-gray-20 hover:border-gray-20 focus-within:border-gray-20 focus-within:bg-gray-20 radius-form content-center disabled:!opacity-100 disabled:bg-gray-10 disabled:border-gray-10 disabled:hover:bg-gray-10 disabled:hover:border-gray-10 disabled:focus-within:bg-gray-10 disabled:focus-within:border-gray-10 disabled:text-gray-30',
        textBadge: 'text-gray-30',
        icon: 'w-6 h-6 fill-gray-70',
        loader: 'w-6 h-6 text-gray-70',
    },
    // transparentní tlačítko
    tertiary: {
        button: 'btn--tertiary bg-transparent radius-form content-center focus-within:border-black',
        icon: 'fill-black group-hover/button:fill-gray-70',
        icon__disabled: 'group-hover/button:fill-black',
        loader: 'w-6 h-6 text-black',
    },
    // textový link
    quaternary: {
        button: 'btn--quaternary bg-transparent text-black !p-0 !flex-grow-0 shrink-0 underline hover:no-underline disabled:hover:underline focus-within:border-black',
        content: '!font-normal !tracking-normal',
        icon: 'w-6 h-6 fill-gray-60 group-hover/button:fill-black',
        loader: 'w-6 h-6 text-gray-60',
    },
    // tool buttony (wishlist, porovnání...)  v detailu produktu/dlaždici
    quinary: {
        button: 'btn--quinary flex-col gap-0 sm:gap-3 sm:flex-row bg-transparent text-gray-60 !p-0 !flex-grow-0 shrink-0 hover:underline hover:text-black focus-within:border-black',
        iconContainer: 'relative flex',
        icon: 'fill-gray-70 group-hover/button:fill-black',
        badgeIconContainer: 'absolute flex items-center justify-center -bottom-[.35rem] -right-[.35rem] bg-black rounded-full w-[1.2rem] h-[1.2rem] border-2 border-white',
        badgeIcon: 'fill-white w-2 h-2',
        loader: 'w-6 h-6 text-gray-70',
    },
    // navigační button slideru
    senary: {
        button: 'btn--senary bg-gray-70 hover:bg-black sm:bg-gray-10/80 text-white sm:text-gray-70 sm:hover:bg-gray-20/80 sm:focus-within:bg-gray-20/80 radius-form content-center disabled:bg-gray-10/80 disabled:opacity-100 sm:disabled:opacity-30',
        icon: 'w-4 h-4 fill-white sm:fill-gray-70',
        loader: 'w-6 h-6 text-white sm:text-gray-70',
    },
    // odebírací tlačítko produktu
    septenary: {
        button: 'btn--septenary bg-transparent !p-0 remove-item-btn',
        icon: 'w-5 h-5 fill-gray-30 group-hover/button:fill-black',
    },
    // kontextové tlačítko v modálním okně
    octonary: {
        button: 'btn--octonary bg-transparent text-gray-70 hover:text-black !p-0 !gap-6 !justify-start',
        icon: 'w-8 h-8 fill-black',
        content: '!text-sm group-hover/button:underline text-left',
    },
    // success tlačítko
    success: {
        button: 'bg-success border border-success text-white hover:bg-success-darker hover:border-success-darker focus-within:border-success-darker focus-within:bg-success-darker disabled:hover:bg-success disabled:opacity-100 disabled:hover:border-success radius-form content-center',
        textBadge: 'text-gray-30',
        icon: 'w-6 h-6 fill-white',
        loader: 'w-6 h-6 text-white',
    },
    // danger tlačítko
    danger: {
        button: 'bg-error border border-error text-white hover:bg-error-darker hover:border-error-darker focus-within:border-error-darker focus-within:bg-error-darker radius-form content-center',
        textBadge: 'text-gray-30',
        icon: 'w-6 h-6 fill-white',
        loader: 'w-6 h-6 text-white',
    },
    // velikost XSM
    xsm: {
        button: 'px-8 py-2',
        content: 'text-tag font-normal',
        textBadge: 'text-tag',
    },
    // velikost SM
    sm: {
        button: 'px-8 py-4',
        content: 'text-tag font-normal leading-none',
        textBadge: 'text-tag',
    },
    // velikost MD
    md: {
        button: 'px-12 py-5',
        content: 'text-xs font-medium tracking-wide',
        textBadge: 'text-xs',
    },
    // velikost LG
    lg: {
        button: 'px-14 py-6',
        content: 'text-s font-medium tracking-wide',
        textBadge: 'text-s',
    },
    // velikost XL
    xl: {
        button: 'px-24 py-7',
        content: 'text-s font-medium tracking-wide',
        textBadge: 'text-s',
    },
    // velikost XSM/SM
    flexible: {
        button: 'px-8 py-2 xl:py-4',
        content: 'text-tag font-normal',
        textBadge: 'text-tag',
    },
};
export function buttonClassNames(...args) {
    return getMemoizedFinalClassnames('buttonClassNames', buttonDefaultClassNames, ...args);
}

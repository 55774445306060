import { useGlobalState } from '@edeeone/juan-core/GlobalState';
const MODAL_SERVER_ACTION = 'modalServerAction';
const resultPath = [`${MODAL_SERVER_ACTION}result`];
const pendingPath = [`${MODAL_SERVER_ACTION}pending`];
const modalConfigurationPath = [`${MODAL_SERVER_ACTION}modalConfiguration`];
let timer;
export const useServerActionModal = (props) => {
    const { closeModalTimeoutMs = 1400 } = props ?? {};
    const { setState, removeState, getState } = useGlobalState();
    const triggerModalServerAction = async (action, propModalConfiguration) => {
        setState(pendingPath, true);
        const modalConfiguration = propModalConfiguration ?? getState(modalConfigurationPath);
        setState(modalConfigurationPath, modalConfiguration);
        const result = await action();
        setState(resultPath, result);
        setState(pendingPath, false);
    };
    const closeModal = () => {
        removeState(resultPath);
        removeState(pendingPath);
        removeState(modalConfigurationPath);
    };
    const closeModalWithDelay = () => {
        setTimeout(() => {
            closeModal();
            clearTimeout(timer);
        }, closeModalTimeoutMs);
    };
    return {
        triggerModalServerAction,
        closeModalWithDelay,
        closeModal,
        result: getState(resultPath),
        pending: getState(pendingPath),
        props: getState(modalConfigurationPath),
    };
};

import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const badgeDefaultClassNames = /* tw */ {
    container: 'badge rounded-full flex items-center justify-center gap-2 px-4 text-white font-medium transition-common',
    container__border: 'border-2 border-white',
    container__positionedOut: 'translate-x-1/2 -translate-y-1/3',
    container__default: 'bg-gray-40 hover:bg-black',
    container__warning: 'bg-red',
    container__info: 'bg-secondary',
    container__positioned: 'absolute top-0 right-0 ',
    container__inline: '',
    container__rounded: 'w-20',
    container__custom: '',
    content: '',
    icon: 'badge__ico text-xs',
    sm: {
        container: 'min-w-[2.2rem] h-[2.2rem] opacity-100 p-3',
        container__border: 'p-2',
        container__positioned: 'absolute top-0 right-0 ',
        container__rounded: 'w-[3.2rem] h-[3.2rem] text-2xs',
        content: 'text-xxs',
        icon: 'w-4 h-4 fill-white -mx-0.5',
    },
    md: {
        container__rounded: 'w-[4.2rem] h-[4.2rem]',
        content: 'text-xs',
    },
    lg: {
        container__rounded: 'w-20 h-20',
        content: 'text-xs',
    },
};
export function badgeClassNames(...args) {
    return getMemoizedFinalClassnames('badgeClassNames', badgeDefaultClassNames, ...args);
}

import { jsx as _jsx } from "react/jsx-runtime";
import get from 'lodash/get';
import unset from 'lodash/unset';
import { createContext, useContext, useState } from 'react';
const globalStateContext = {
    globalState: {},
    setGlobalState: () => {
        return false;
    },
};
function setImmutableValue(obj, path, value) {
    if (path.length === 0) {
        return value;
    }
    const [head, ...tail] = path;
    if (typeof head === 'number' && Array.isArray(obj)) {
        return [
            ...obj.slice(0, head),
            setImmutableValue(obj[head] || {}, tail, value),
            ...obj.slice(head + 1),
        ];
    }
    else {
        return {
            ...obj,
            [head]: setImmutableValue(obj[head] || {}, tail, value),
        };
    }
}
export const GlobalStateContext = createContext(globalStateContext);
export const GlobalStateProvider = function ({ children, }) {
    const [globalState, setGlobalState] = useState({});
    return (_jsx(GlobalStateContext.Provider, { value: {
            globalState,
            setGlobalState,
        }, children: children }));
};
export const useGlobalState = () => {
    const { globalState, setGlobalState } = useContext(GlobalStateContext);
    const getState = (path) => {
        return get(globalState, path);
    };
    const setState = (path, value) => {
        setGlobalState((prevState) => {
            return setImmutableValue(prevState, path, value);
        });
    };
    const removeState = (path) => {
        setGlobalState((prevState) => {
            return unset(prevState, path);
        });
    };
    return { globalState, setGlobalState, setState, getState, removeState };
};

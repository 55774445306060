import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { isLocalURL } from 'next/dist/shared/lib/router/utils/is-local-url';
import { default as NextLink } from 'next/link';
import { Children, cloneElement } from 'react';
const EMPTY_URL = '/empty';
export const Link = function ({ href, children, native, legacyBehavior = true, ...props }) {
    if (typeof children === 'string') {
        children = _jsx("a", { children: children });
    }
    const IS_SERVER = typeof window === 'undefined';
    const child = Children.only(children);
    const finalHref = typeof href === 'string' ? href : href?.pathname;
    let isLocal = false;
    /* eslint-disable react-hooks/rules-of-hooks */
    isLocal = Boolean(IS_SERVER
        ? finalHref &&
            (finalHref.startsWith('http')
                ? finalHref.indexOf(useLocation().host || 'juan-host') !== -1
                : true)
        : finalHref && isLocalURL(finalHref));
    /* eslint-enable react-hooks/rules-of-hooks */
    const isExternal = !isLocal;
    const isFile = finalHref?.toString().startsWith('/file/edee/');
    const computedTarget = child.target || (isExternal || isFile ? '_blank' : undefined);
    const computedRel = child.rel || (isExternal || isFile ? 'noopener noreferrer' : undefined);
    if (isFile || isExternal || native) {
        return cloneElement(child, {
            ...(child?.props || {}),
            href: finalHref ?? EMPTY_URL,
            target: computedTarget,
            rel: computedRel,
        });
    }
    return (_jsx(NextLink, { href: href, passHref: true, ...props, legacyBehavior: legacyBehavior, children: cloneElement(child, {
            ...(child?.props || {}),
        }) }));
};
